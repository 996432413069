import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { domain, clientId } from '../auth_config.json';
import { Auth0Plugin } from './auth';
import LoadScript from 'vue-plugin-load-script';
import './styles/normalize.css';
import './styles/common.css';

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(LoadScript);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
